body.nonresponsive {
    /* Always float the navbar header */
    /* Undo the collapsing navbar */
    /* Always apply the floated nav */
    /* Redeclare since we override the float above */
    /* Undo custom dropdowns */
    /*xs*/
    /*sm*/
    /*medium*/
}

body.nonresponsive body {
    min-width: 960px;
}

body.nonresponsive .container {
    width: 960px !important;
    max-width: none !important;
}

body.nonresponsive .navbar-header {
    float: left;
}

body.nonresponsive .container > .navbar-header,
body.nonresponsive .container-fluid > .navbar-header,
body.nonresponsive .container > .navbar-collapse,
body.nonresponsive .container-fluid > .navbar-collapse {
    margin-left: 0;
    margin-right: 0;
}

body.nonresponsive .navbar-collapse {
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important;
}

body.nonresponsive .navbar-toggle {
    display: none;
}

body.nonresponsive .navbar-collapse {
    border-top: 0;
}

body.nonresponsive .navbar-brand {
    margin-left: -15px;
}

body.nonresponsive .navbar-form {
    float: left;
    border: none;
    margin: 7px 15px;
    padding: 0 15px;
}

body.nonresponsive .navbar-form .form-control {
    width: auto;
}

body.nonresponsive .navbar-nav {
    float: left;
    margin: 0;
}

body.nonresponsive .navbar-nav > li {
    float: left;
}

body.nonresponsive .navbar-nav > li > a {
    padding: 15px;
}

body.nonresponsive .navbar-nav.navbar-right {
    float: right;
}

body.nonresponsive .navbar .navbar-nav .open .dropdown-menu {
    position: absolute;
    float: left;
    background-color: #fff;
    border: 1px solid #cccccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-width: 0 1px 1px;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

body.nonresponsive .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #333;
}

body.nonresponsive .navbar .navbar-nav .open .dropdown-menu > li > a:hover,
body.nonresponsive .navbar .navbar-nav .open .dropdown-menu > li > a:focus,
body.nonresponsive .navbar .navbar-nav .open .dropdown-menu > .active > a,
body.nonresponsive .navbar .navbar-nav .open .dropdown-menu > .active > a:hover,
body.nonresponsive .navbar .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #fff !important;
    background-color: #428bca !important;
}

body.nonresponsive .navbar .navbar-nav .open .dropdown-menu > .disabled > a,
body.nonresponsive .navbar .navbar-nav .open .dropdown-menu > .disabled > a:hover,
body.nonresponsive .navbar .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #999 !important;
    background-color: transparent !important;
}

body.nonresponsive .visible-xs,
body.nonresponsive .visible-xs-inline,
body.nonresponsive .visible-sm,
body.nonresponsive .visible-sm-inline {
    display: none !important;
}

body.nonresponsive .col-xs-1, body.nonresponsive .col-xs-2, body.nonresponsive .col-xs-3, body.nonresponsive .col-xs-4, body.nonresponsive .col-xs-5, body.nonresponsive .col-xs-6, body.nonresponsive .col-xs-7, body.nonresponsive .col-xs-8, body.nonresponsive .col-xs-9, body.nonresponsive .col-xs-10, body.nonresponsive .col-xs-11, body.nonresponsive .col-xs-12 {
    float: left;
}

body.nonresponsive .col-xs-12 {
    width: 100%;
}

body.nonresponsive .col-xs-11 {
    width: 91.66666667%;
}

body.nonresponsive .col-xs-10 {
    width: 83.33333333%;
}

body.nonresponsive .col-xs-9 {
    width: 75%;
}

body.nonresponsive .col-xs-8 {
    width: 66.66666667%;
}

body.nonresponsive .col-xs-7 {
    width: 58.33333333%;
}

body.nonresponsive .col-xs-6 {
    width: 50%;
}

body.nonresponsive .col-xs-5 {
    width: 41.66666667%;
}

body.nonresponsive .col-xs-4 {
    width: 33.33333333%;
}

body.nonresponsive .col-xs-3 {
    width: 25%;
}

body.nonresponsive .col-xs-2 {
    width: 16.66666667%;
}

body.nonresponsive .col-xs-1 {
    width: 8.33333333%;
}

body.nonresponsive .col-xs-pull-12 {
    right: 100%;
}

body.nonresponsive .col-xs-pull-11 {
    right: 91.66666667%;
}

body.nonresponsive .col-xs-pull-10 {
    right: 83.33333333%;
}

body.nonresponsive .col-xs-pull-9 {
    right: 75%;
}

body.nonresponsive .col-xs-pull-8 {
    right: 66.66666667%;
}

body.nonresponsive .col-xs-pull-7 {
    right: 58.33333333%;
}

body.nonresponsive .col-xs-pull-6 {
    right: 50%;
}

body.nonresponsive .col-xs-pull-5 {
    right: 41.66666667%;
}

body.nonresponsive .col-xs-pull-4 {
    right: 33.33333333%;
}

body.nonresponsive .col-xs-pull-3 {
    right: 25%;
}

body.nonresponsive .col-xs-pull-2 {
    right: 16.66666667%;
}

body.nonresponsive .col-xs-pull-1 {
    right: 8.33333333%;
}

body.nonresponsive .col-xs-pull-0 {
    right: auto;
}

body.nonresponsive .col-xs-push-12 {
    left: 100%;
}

body.nonresponsive .col-xs-push-11 {
    left: 91.66666667%;
}

body.nonresponsive .col-xs-push-10 {
    left: 83.33333333%;
}

body.nonresponsive .col-xs-push-9 {
    left: 75%;
}

body.nonresponsive .col-xs-push-8 {
    left: 66.66666667%;
}

body.nonresponsive .col-xs-push-7 {
    left: 58.33333333%;
}

body.nonresponsive .col-xs-push-6 {
    left: 50%;
}

body.nonresponsive .col-xs-push-5 {
    left: 41.66666667%;
}

body.nonresponsive .col-xs-push-4 {
    left: 33.33333333%;
}

body.nonresponsive .col-xs-push-3 {
    left: 25%;
}

body.nonresponsive .col-xs-push-2 {
    left: 16.66666667%;
}

body.nonresponsive .col-xs-push-1 {
    left: 8.33333333%;
}

body.nonresponsive .col-xs-push-0 {
    left: auto;
}

body.nonresponsive .col-xs-offset-12 {
    margin-left: 100%;
}

body.nonresponsive .col-xs-offset-11 {
    margin-left: 91.66666667%;
}

body.nonresponsive .col-xs-offset-10 {
    margin-left: 83.33333333%;
}

body.nonresponsive .col-xs-offset-9 {
    margin-left: 75%;
}

body.nonresponsive .col-xs-offset-8 {
    margin-left: 66.66666667%;
}

body.nonresponsive .col-xs-offset-7 {
    margin-left: 58.33333333%;
}

body.nonresponsive .col-xs-offset-6 {
    margin-left: 50%;
}

body.nonresponsive .col-xs-offset-5 {
    margin-left: 41.66666667%;
}

body.nonresponsive .col-xs-offset-4 {
    margin-left: 33.33333333%;
}

body.nonresponsive .col-xs-offset-3 {
    margin-left: 25%;
}

body.nonresponsive .col-xs-offset-2 {
    margin-left: 16.66666667%;
}

body.nonresponsive .col-xs-offset-1 {
    margin-left: 8.33333333%;
}

body.nonresponsive .col-xs-offset-0 {
    margin-left: 0;
}

body.nonresponsive .col-sm-1, body.nonresponsive .col-sm-2, body.nonresponsive .col-sm-3, body.nonresponsive .col-sm-4, body.nonresponsive .col-sm-5, body.nonresponsive .col-sm-6, body.nonresponsive .col-sm-7, body.nonresponsive .col-sm-8, body.nonresponsive .col-sm-9, body.nonresponsive .col-sm-10, body.nonresponsive .col-sm-11, body.nonresponsive .col-sm-12 {
    float: left;
}

body.nonresponsive .col-sm-12 {
    width: 100%;
}

body.nonresponsive .col-sm-11 {
    width: 91.66666667%;
}

body.nonresponsive .col-sm-10 {
    width: 83.33333333%;
}

body.nonresponsive .col-sm-9 {
    width: 75%;
}

body.nonresponsive .col-sm-8 {
    width: 66.66666667%;
}

body.nonresponsive .col-sm-7 {
    width: 58.33333333%;
}

body.nonresponsive .col-sm-6 {
    width: 50%;
}

body.nonresponsive .col-sm-5 {
    width: 41.66666667%;
}

body.nonresponsive .col-sm-4 {
    width: 33.33333333%;
}

body.nonresponsive .col-sm-3 {
    width: 25%;
}

body.nonresponsive .col-sm-2 {
    width: 16.66666667%;
}

body.nonresponsive .col-sm-1 {
    width: 8.33333333%;
}

body.nonresponsive .col-sm-pull-12 {
    right: 100%;
}

body.nonresponsive .col-sm-pull-11 {
    right: 91.66666667%;
}

body.nonresponsive .col-sm-pull-10 {
    right: 83.33333333%;
}

body.nonresponsive .col-sm-pull-9 {
    right: 75%;
}

body.nonresponsive .col-sm-pull-8 {
    right: 66.66666667%;
}

body.nonresponsive .col-sm-pull-7 {
    right: 58.33333333%;
}

body.nonresponsive .col-sm-pull-6 {
    right: 50%;
}

body.nonresponsive .col-sm-pull-5 {
    right: 41.66666667%;
}

body.nonresponsive .col-sm-pull-4 {
    right: 33.33333333%;
}

body.nonresponsive .col-sm-pull-3 {
    right: 25%;
}

body.nonresponsive .col-sm-pull-2 {
    right: 16.66666667%;
}

body.nonresponsive .col-sm-pull-1 {
    right: 8.33333333%;
}

body.nonresponsive .col-sm-pull-0 {
    right: auto;
}

body.nonresponsive .col-sm-push-12 {
    left: 100%;
}

body.nonresponsive .col-sm-push-11 {
    left: 91.66666667%;
}

body.nonresponsive .col-sm-push-10 {
    left: 83.33333333%;
}

body.nonresponsive .col-sm-push-9 {
    left: 75%;
}

body.nonresponsive .col-sm-push-8 {
    left: 66.66666667%;
}

body.nonresponsive .col-sm-push-7 {
    left: 58.33333333%;
}

body.nonresponsive .col-sm-push-6 {
    left: 50%;
}

body.nonresponsive .col-sm-push-5 {
    left: 41.66666667%;
}

body.nonresponsive .col-sm-push-4 {
    left: 33.33333333%;
}

body.nonresponsive .col-sm-push-3 {
    left: 25%;
}

body.nonresponsive .col-sm-push-2 {
    left: 16.66666667%;
}

body.nonresponsive .col-sm-push-1 {
    left: 8.33333333%;
}

body.nonresponsive .col-sm-push-0 {
    left: auto;
}

body.nonresponsive .col-sm-offset-12 {
    margin-left: 100%;
}

body.nonresponsive .col-sm-offset-11 {
    margin-left: 91.66666667%;
}

body.nonresponsive .col-sm-offset-10 {
    margin-left: 83.33333333%;
}

body.nonresponsive .col-sm-offset-9 {
    margin-left: 75%;
}

body.nonresponsive .col-sm-offset-8 {
    margin-left: 66.66666667%;
}

body.nonresponsive .col-sm-offset-7 {
    margin-left: 58.33333333%;
}

body.nonresponsive .col-sm-offset-6 {
    margin-left: 50%;
}

body.nonresponsive .col-sm-offset-5 {
    margin-left: 41.66666667%;
}

body.nonresponsive .col-sm-offset-4 {
    margin-left: 33.33333333%;
}

body.nonresponsive .col-sm-offset-3 {
    margin-left: 25%;
}

body.nonresponsive .col-sm-offset-2 {
    margin-left: 16.66666667%;
}

body.nonresponsive .col-sm-offset-1 {
    margin-left: 8.33333333%;
}

body.nonresponsive .col-sm-offset-0 {
    margin-left: 0;
}

body.nonresponsive .col-md-1, body.nonresponsive .col-md-2, body.nonresponsive .col-md-3, body.nonresponsive .col-md-4, body.nonresponsive .col-md-5, body.nonresponsive .col-md-6, body.nonresponsive .col-md-7, body.nonresponsive .col-md-8, body.nonresponsive .col-md-9, body.nonresponsive .col-md-10, body.nonresponsive .col-md-11, body.nonresponsive .col-md-12 {
    float: left;
}

body.nonresponsive .col-md-12 {
    width: 100%;
}

body.nonresponsive .col-md-11 {
    width: 91.66666667%;
}

body.nonresponsive .col-md-10 {
    width: 83.33333333%;
}

body.nonresponsive .col-md-9 {
    width: 75%;
}

body.nonresponsive .col-md-8 {
    width: 66.66666667%;
}

body.nonresponsive .col-md-7 {
    width: 58.33333333%;
}

body.nonresponsive .col-md-6 {
    width: 50%;
}

body.nonresponsive .col-md-5 {
    width: 41.66666667%;
}

body.nonresponsive .col-md-4 {
    width: 33.33333333%;
}

body.nonresponsive .col-md-3 {
    width: 25%;
}

body.nonresponsive .col-md-2 {
    width: 16.66666667%;
}

body.nonresponsive .col-md-1 {
    width: 8.33333333%;
}

body.nonresponsive .col-md-pull-12 {
    right: 100%;
}

body.nonresponsive .col-md-pull-11 {
    right: 91.66666667%;
}

body.nonresponsive .col-md-pull-10 {
    right: 83.33333333%;
}

body.nonresponsive .col-md-pull-9 {
    right: 75%;
}

body.nonresponsive .col-md-pull-8 {
    right: 66.66666667%;
}

body.nonresponsive .col-md-pull-7 {
    right: 58.33333333%;
}

body.nonresponsive .col-md-pull-6 {
    right: 50%;
}

body.nonresponsive .col-md-pull-5 {
    right: 41.66666667%;
}

body.nonresponsive .col-md-pull-4 {
    right: 33.33333333%;
}

body.nonresponsive .col-md-pull-3 {
    right: 25%;
}

body.nonresponsive .col-md-pull-2 {
    right: 16.66666667%;
}

body.nonresponsive .col-md-pull-1 {
    right: 8.33333333%;
}

body.nonresponsive .col-md-pull-0 {
    right: auto;
}

body.nonresponsive .col-md-push-12 {
    left: 100%;
}

body.nonresponsive .col-md-push-11 {
    left: 91.66666667%;
}

body.nonresponsive .col-md-push-10 {
    left: 83.33333333%;
}

body.nonresponsive .col-md-push-9 {
    left: 75%;
}

body.nonresponsive .col-md-push-8 {
    left: 66.66666667%;
}

body.nonresponsive .col-md-push-7 {
    left: 58.33333333%;
}

body.nonresponsive .col-md-push-6 {
    left: 50%;
}

body.nonresponsive .col-md-push-5 {
    left: 41.66666667%;
}

body.nonresponsive .col-md-push-4 {
    left: 33.33333333%;
}

body.nonresponsive .col-md-push-3 {
    left: 25%;
}

body.nonresponsive .col-md-push-2 {
    left: 16.66666667%;
}

body.nonresponsive .col-md-push-1 {
    left: 8.33333333%;
}

body.nonresponsive .col-md-push-0 {
    left: auto;
}

body.nonresponsive .col-md-offset-12 {
    margin-left: 100%;
}

body.nonresponsive .col-md-offset-11 {
    margin-left: 91.66666667%;
}

body.nonresponsive .col-md-offset-10 {
    margin-left: 83.33333333%;
}

body.nonresponsive .col-md-offset-9 {
    margin-left: 75%;
}

body.nonresponsive .col-md-offset-8 {
    margin-left: 66.66666667%;
}

body.nonresponsive .col-md-offset-7 {
    margin-left: 58.33333333%;
}

body.nonresponsive .col-md-offset-6 {
    margin-left: 50%;
}

body.nonresponsive .col-md-offset-5 {
    margin-left: 41.66666667%;
}

body.nonresponsive .col-md-offset-4 {
    margin-left: 33.33333333%;
}

body.nonresponsive .col-md-offset-3 {
    margin-left: 25%;
}

body.nonresponsive .col-md-offset-2 {
    margin-left: 16.66666667%;
}

body.nonresponsive .col-md-offset-1 {
    margin-left: 8.33333333%;
}

body.nonresponsive .col-md-offset-0 {
    margin-left: 0;
}
