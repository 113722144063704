.modNewsFilterBar {
    display: inline-block;
}

.modNewsFilterBar button {
    white-space: nowrap;
}

.modNewsFilterBar .dropdown-menu {
    max-height: 205px;
    overflow: auto;
}

.modMiniNewsBar {
    padding: 15px;
    background: #f1f1f1;
    border-radius: 4px;
}
