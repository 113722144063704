@font-face {
    font-family: 'HelveticaNeue';
    src: url("../fonts/0b3a3fca-0fad-402b-bd38-fdcbad1ef776.eot");
    src: url("../fonts/0b3a3fca-0fad-402b-bd38-fdcbad1ef776.eot?#iefix") format("embedded-opentype"), url("../fonts/d5af76d8-a90b-4527-b3a3-182207cc3250.woff") format("woff"), url("../fonts/1d238354-d156-4dde-89ea-4770ef04b9f9.ttf") format("truetype"), url("../fonts/b68875cb-14a9-472e-8177-0247605124d7.svg#open_sanslight") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url("../fonts/c07fef9e-a934-42d7-92ad-69205f2b8a00.eot");
    src: url("../fonts/c07fef9e-a934-42d7-92ad-69205f2b8a00.eot?#iefix") format("embedded-opentype"), url("../fonts/14ff6081-326d-4dae-b778-d7afa66166fc.woff") format("woff"), url("../fonts/8fda1e47-19be-46c7-8d83-8d4fb35572f0.ttf") format("truetype"), url("../fonts/f751c8ae-1057-46d9-8d74-62592e002568.svg#open_sanslight") format("svg");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url("../fonts/58a5cbff-d570-4c18-a5e3-60868dc07ae8.eot");
    src: url("../fonts/58a5cbff-d570-4c18-a5e3-60868dc07ae8.eot?#iefix") format("embedded-opentype"), url("../fonts/2c056da8-4920-4e20-8c69-8a6b315458a9.woff") format("woff"), url("../fonts/2381d918-136d-444f-8391-db0cba6da388.ttf") format("truetype"), url("../fonts/d0697971-6f58-4597-942e-8beabd1adc87.svg#open_sanslight") format("svg");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url("../fonts/9eb382e7-b9a0-4ec0-86b3-7a690b9e127b.eot");
    src: url("../fonts/9eb382e7-b9a0-4ec0-86b3-7a690b9e127b.eot?#iefix") format("embedded-opentype"), url("../fonts/628a0837-f9e0-4b32-83c4-d99689b9da93.woff") format("woff"), url("../fonts/4d9ecce0-f253-49fd-8623-b0f896b12794.ttf") format("truetype"), url("../fonts/dcdd4c31-466f-43f5-85c9-9bcdcd814139.svg#open_sanslight") format("svg");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'AvantGarde';
    src: url("../fonts/b74e5f86-4477-435a-abdf-32d8215f21bc.eot");
    src: url("../fonts/b74e5f86-4477-435a-abdf-32d8215f21bc.eot?#iefix") format("embedded-opentype"), url("../fonts/c18aee57-7585-4a8b-9a1c-69d226301d73.woff") format("woff"), url("../fonts/44ccbb2b-0fbc-4d95-b1da-0aa73bd26fb0.ttf") format("truetype"), url("../fonts/f2de529c-11d2-43b7-be7c-05c5aaeaf133.svg#open_sanslight") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AvantGarde';
    src: url("../fonts/2a334c60-3e0d-4f43-b0e9-5284ea33961a.eot");
    src: url("../fonts/2a334c60-3e0d-4f43-b0e9-5284ea33961a.eot?#iefix") format("embedded-opentype"), url("../fonts/c68f0543-0caf-4988-b234-355520476b8c.woff") format("woff"), url("../fonts/2d4f1d98-ddb3-4acc-ae78-c8b1863f780e.ttf") format("truetype"), url("../fonts/80f98a03-905d-49e6-8614-cec7c32ca4f2.svg#open_sanslight") format("svg");
    font-weight: 300;
    font-style: normal;
}

/*-----------------------------------------------------------------------------------------------*/
h1 {
    margin: 14px 0px;
    color: #2F6793;
    font-weight: 300;
    font-size: 2.9em;
    line-height: 1.05em;
    font-family: AvantGarde, HelveticaNeue, Arial, Helvetica, Sans-Serif;
}

h2 {
    margin: 14px 0px;
    color: #D31E7D;
    font-weight: 300;
    font-size: 2.2em;
    line-height: 1.15em;
    font-family: AvantGarde, HelveticaNeue, Arial, Helvetica, Sans-Serif;
}

h3 {
    margin: 20px 0px 14px 0px;
    color: #000000;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 1.1em;
    line-height: 1.3em;
}

p {
    margin: 14px 0px;
}

h1:first-child,
h2:first-child,
h3:first-child,
p:first-child {
    margin-top: 0px;
}

h1:last-child,
h2:last-child,
h3:last-child,
p:last-child {
    margin-bottom: 0px;
}

a:link, a:active, a:visited {
    color: #D31E7D;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

a img {
    border: 0px;
}

a.pdf {
    display: inline-block;
    padding-left: 22px;
    background: url("../images/pdf.png") left 2px no-repeat;
}

a.word {
    display: inline-block;
    padding-left: 22px;
    background: url("../images/doc.png") left 2px no-repeat;
}

.clearer {
    clear: both;
    height: 0px;
    overflow: hidden;
    font-size: 0px;
    line-height: 0px;
}

.clear:after {
    content: " ";
    clear: both;
    display: block;
}

hr {
    height: 1px;
    overflow: hidden;
    color: #666666;
    line-height: 1px;
    border: none;
    background-color: #666666;
}

.spacer {
    margin-top: 15px;
    margin-bottom: 15px;
}

.spacer_top {
    margin-top: 15px;
}

.spacer_bottom {
    margin-bottom: 15px;
}

.padder {
    padding-left: 15px;
    padding-right: 15px;
}

.padder_right {
    padding-right: 15px;
}

.padder_left {
    padding-left: 15px;
}

.nopadding {
    padding: 0 !important;
}

.nopadding_right {
    padding-right: 0 !important;
}

.nopadding_left {
    padding-left: 0 !important;
}

.push_right {
    float: right;
}

.push_left {
    float: left;
}

.btn_block {
    display: block;
    float: none;
}

a.link_block {
    display: block;
    padding: 8px 0;
    margin-top: 5px;
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    text-decoration: none;
    padding-left: 15px;
    padding-right: 25px;
    position: relative;
}

a.link_block:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

a.link_block .glyphicon,
a.link_block_lg .glyphicon,
a.link_block_md .glyphicon,
a.link_block_sm .glyphicon,
a.link_block_xs .glyphicon {
    position: absolute;
    top: 50%;
    margin-top: -0.5em;
    right: 10px;
}

img.alignright {
    float: right;
    margin-left: 20px;
    margin-bottom: 4px;
    display: block;
}

img.alignleft {
    float: left;
    margin-right: 20px;
    margin-bottom: 4px;
    display: block;
}

img.aligncenter {
    text-align: center;
    margin: 1em auto 5px auto;
    clear: both;
    display: block;
}

img.fullwidth {
    margin: 0px;
    display: block;
}

/*-----------------------------------------------------------------------------------------------*/
body {
    margin: 0px;
    color: #000000;
    line-height: 1.5em;
    font-family: HelveticaNeue, Arial, Helvetica, Sans-Serif;
    padding-top: 215px;
    /*transition: padding 0.3s ease;
    -moz-transition: padding 0.3s ease;
    -webkit-transition: padding 0.3s ease;
    -ms-transition: padding 0.3s ease;
    -o-transition: padding 0.3s ease;*/
}

body > form {
    margin: 0px;
}

.blur {
    -webkit-filter: blur(7px);
    -moz-filter: blur(7px);
    filter: blur(7px);
}

.cke_editable body {
    padding: 0 !important;
    transition: none !important;
}

/*-----------------------------------------------------------------------------------------------*/
#header {
    font-weight: 300;
    font-family: AvantGarde, HelveticaNeue, Arial, Helvetica, Sans-Serif;
}

#header .container {
    position: relative;
}

#header .logo {
    display: block;
    float: left;
    margin-top: 72px;
    margin-bottom: 45px;
    line-height: 0px;
    width: 200px;
}

#header .header_nav {
    position: absolute;
    top: 10px;
    right: 15px;
}

#header .header_nav ul {
    display: block;
    float: left;
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    vertical-align: top;
}

#header .header_nav ul li {
    display: block;
    float: left;
    margin: 0px;
    padding: 0px;
}

#header .header_nav ul li a {
    position: relative;
    display: block;
    padding: 3px 20px;
    color: #000000;
    font-size: 1.21em;
    line-height: 1em;
}

#header .header_nav ul li a:before {
    position: absolute;
    display: block;
    padding: 2px 0px;
    top: 0px;
    left: -5px;
    content: "|";
}

#header .header_nav ul li:first-child a:before {
    display: none;
}

#header .header_nav .search_trigger {
    display: block;
    float: left;
    margin-left: 3px;
    line-height: 0px;
    vertical-align: top;
}

#header .header_strapline {
    color: #d31e7d;
    float: right;
    font-size: 1.64em;
    line-height: 1.35em;
    margin-top: 85px;
    max-width: 420px;
    text-align: right;
}

#header .header_strapline a {
    color: #D31E7D;
}

#header .header_strapline p {
    font-size: 1.9em;
    line-height: 1.2em;
    margin: 0px;
}

.nav_wrapper {
    background-color: #ffffff;
    box-shadow: 1px -1px 5px -2px;
}

#header .logo, #header .header_strapline {
    transition: margin 0.2s ease;
    -moz-transition: margin 0.2s ease;
    -webkit-transition: margin 0.2s ease;
    -ms-transition: margin 0.2s ease;
    -o-transition: margin 0.2s ease;
}

/*
 body.nav-scrolled{
    padding-top: 10px !important;
}*/
.nav-scrolled .logo {
    margin-bottom: 30px !important;
    margin-top: 60px !important;
}

.nav-scrolled .header_strapline {
    margin-top: 70px !important;
}

/*-----------------------------------------------------------------------------------------------*/
.contact_prompt {
    /*margin-bottom: 30px;*/
    padding: 16px 0px;
    background-color: #8EAB0E;
}

.contact_prompt p {
    margin: 0px;
    color: #000000;
    text-align: left;
    font-weight: 300;
    font-size: 1.9em;
    line-height: 1.2em;
    font-family: AvantGarde, HelveticaNeue, Arial, Helvetica, Sans-Serif;
}

.contact_prompt strong,
.contact_prompt a {
    color: #000000;
}

.contact_prompt strong {
    font-weight: 300;
}

/*-----------------------------------------------------------------------------------------------*/
.home_intro {
    position: relative;
}

.home_intro > div:first-child img {
    width: 100%;
    height: auto;
}

.home_intro > div + div {
    padding-bottom: 40px;
}

.home_intro h1 + h2 {
    margin-top: -4px;
    margin-bottom: -10px;
    color: #D31E7D;
    font-weight: normal;
    text-transform: uppercase;
    font-size: 1.61em;
    line-height: 1.25em;
}

.home_intro p:last-child > a:first-child:last-child {
    position: absolute;
    display: block;
    bottom: 0px;
    right: 15px;
    padding: 8px 12px;
    color: #FFFFFF;
    text-decoration: none;
    font-size: 1.1em;
    line-height: 1em;
    background-color: #D31E7D;
}

.home_intro p:last-child > a:first-child:last-child:hover {
    background-color: #F33E9D;
}

/*-----------------------------------------------------------------------------------------------*/
.home_latest {
    margin-bottom: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #F1F2EF;
}

.home_latest h2 {
    vertical-align: bottom;
}

.home_latest h2.latest {
    margin-bottom: 30px;
    color: #2F6793;
    font-size: 2.9em;
    line-height: 1em;
}

.home_latest h2.latest span {
    padding-left: 30px;
    vertical-align: bottom;
    font-weight: normal;
    font-size: 0.4em;
    line-height: 1.4em;
    font-family: HelveticaNeue, Arial, Helvetica, Sans-Serif;
}

.home_latest h2.upcoming {
    padding-top: 9px;
    margin-bottom: 30px;
    color: #21435D;
    font-size: 2.275em;
    line-height: 1.05em;
}

/*-----------------------------------------------------------------------------------------------*/
#page {
    padding-bottom: 45px;
    border-bottom: 1px solid #D1D1D1;
}

#content p,
#content a,
#content li {
    font-size: 1.1em;
}

/*-----------------------------------------------------------------------------------------------*/
.lower_banner {
    margin-bottom: 30px;
    overflow: hidden;
    text-align: center;
}

#page > #content:first-child {
    margin-top: 30px;
    margin-bottom: 30px;
}

.lower_banner img {
    width: auto;
    height: 263px;
}

.lower_banner .container {
    position: relative;
}

.lower_banner p {
    position: absolute;
    bottom: 20px;
    left: 15px;
    margin: 0px;
    padding: 20px 30px 20px 15px;
    color: #FFFFFF;
    font-weight: 300;
    font-size: 3.58em;
    line-height: 1em;
    font-family: AvantGarde, HelveticaNeue, Arial, Helvetica, Sans-Serif;
    background-color: #A71B64;
}

/*-----------------------------------------------------------------------------------------------*/
.lower_nav {
    margin-bottom: 95px;
    padding: 15px 0px;
    background-color: #D31E7D;
}

.lower_nav ul {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

.lower_nav ul li {
    display: block;
    margin: 0px;
    padding: 0px;
}

.lower_nav .glyphicon {
    display: none !important;
}

.lower_nav ul li a {
    display: block;
    margin: 0px 15px;
    padding: 5px 0px;
    color: #FFFFFF;
    font-weight: 300;
    font-size: 1.21em;
    line-height: 1.4em;
    font-family: AvantGarde,HelveticaNeue,Arial,Helvetica,Sans-Serif;
    border-top: 1px solid #AE1967;
    white-space: normal;
}

.lower_nav ul li.dropdown.active + li > a {
    border-top: none;
}

.lower_nav ul li:first-child a {
    border-top: none;
}

.lower_nav > ul > li:first-child > a {
    padding-top: 0px;
}

.lower_nav > ul > li:last-child > a {
    padding-bottom: 0px;
}

.lower_nav > ul > li.active > ul {
    position: static;
    display: block;
    padding-top: 8px;
    padding-bottom: 8px;
    float: none;
    min-width: 0px;
    font-size: inherit;
    background-color: #951357;
    box-shadow: none;
}

.lower_nav > ul > li.active > ul li a {
    padding-top: 2px;
    padding-bottom: 2px;
    border: none;
    color: #FFD5ED;
    font-size: 1.1em;
}

.lower_nav > ul > li.active > ul li.active a,
.lower_nav > ul > li.active > ul li a:hover {
    background: transparent;
}

.lower_nav > ul > li.active > ul li a:hover {
    color: #FFFFFF;
    text-decoration: underline;
}

/*-----------------------------------------------------------------------------------------------*/
.prompt {
    float: left;
    padding: 0px 15px;
    width: 20%;
}

.prompt .prompt_icon {
    float: left;
    margin-top: 3px;
    margin-bottom: 10px;
    width: 52px;
    height: 52px;
}

.prompt .prompt_icon img {
    max-width: 100%;
    max-height: 100%;
    width: auto !important;
    height: auto !important;
}

.prompt .prompt_text {
    float: right;
    width: 132px;
}

.prompt .prompt_text h3 {
    margin: 0px;
    color: #3E3E3E;
    text-transform: none;
    font-weight: 300;
    font-size: 1.55em;
    line-height: 1.2em;
    font-family: AvantGarde,HelveticaNeue,Arial,Helvetica,Sans-Serif;
}

.prompt .prompt_text p {
    margin: 6px 0px 0px 0px;
    color: #6E6E6E;
    font-weight: 300;
    font-size: 0.95em;
    line-height: 1.25em;
    font-family: AvantGarde,HelveticaNeue,Arial,Helvetica,Sans-Serif;
}

.prompt p a {
    color: #D31E7D;
}

.prompt .icons {
    float: left;
    margin-top: 3px;
    margin-bottom: 10px;
    width: 52px;
    height: 52px;
}

.prompt .icons > a {
    display: block;
    float: left;
    margin-bottom: 2px;
    width: 25px;
    height: 25px;
    line-height: 0px;
}

.prompt .icons > a > img {
    width: 25px;
    height: 25px;
}

/*.prompt > img {
    display: block;
    float: left;
    margin-top: 3px;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 52px;
}

.lower_prompts .prompt {
    margin-bottom: 52px;
}*/
.module_featured_read_more a,
.next_week {
    background-color: #d31e7d;
    bottom: 15px;
    color: #ffffff !important;
    display: inline;
    font-size: 1.1em;
    line-height: 1em;
    padding: 6px 12px;
    right: 15px;
    text-decoration: none;
}

.module_featured_content {
    margin-bottom: 30px;
}

/*-----------------------------------------------------------------------------------------------*/
.breadcrumb {
    margin-bottom: 10px;
    padding: 0px;
    background: transparent;
}

.breadcrumb,
.breadcrumb > .active {
    color: #D31E7D;
}

.breadcrumb a,
.breadcrumb > li + li:before {
    color: #000000;
}

.breadcrumb > li {
    font-size: 0.9em !important;
}

/*-----------------------------------------------------------------------------------------------*/
.home_prompts {
    margin-top: 30px;
    margin-bottom: 40px;
}

/*.btn-primary.next, .btn-primary.prev {
    background-color: #000000;
    bottom: 15px;
    color: #ffffff;
    display: block;
    font-size: 1.1em;
    line-height: 1em;
    padding: 5px 10px;
    position: absolute;
    right: 15px;
}*/
.btn-default.prev,
.btn-primary.next {
    background-color: #65696e;
    background-image: none !important;
    border: 1px solid #65696e !important;
    border-radius: 0 !important;
    color: #ffffff !important;
    text-shadow: none !important;
}

/*-----------------------------------------------------------------------------------------------*/
#footer_nav {
    font-size: 1.22em;
    line-height: 1.4em;
}

#page + #footer_nav {
    margin-top: 20px;
}

#footer_nav ul {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

#footer_nav ul li {
    margin: 0px;
    padding: 0px;
}

#footer_nav .glyphicon {
    display: none;
}

#footer_nav .container > ul > li {
    display: block;
    float: left;
    margin-left: 15px;
    margin-bottom: 20px;
}

#footer_nav .container > ul > li.li_nav_home {
    display: none;
}

#footer_nav .container > ul > li:first-child,
#footer_nav .container > ul > li.li_nav_home + li {
    margin-left: 0px;
}

#footer_nav .container > ul > li a {
    color: #000000;
}

#footer_nav .container > ul > li > .dropdown-menu {
    position: static;
    display: block;
    float: none;
    margin-top: 20px;
    min-width: 0px;
    font-size: inherit;
    border: none;
    background: transparent;
    box-shadow: none;
}

#footer_nav .container > ul > li > .dropdown-menu > li > a {
    padding: 2px 0px;
    max-width: 175px;
    color: #707070;
    line-height: 1.3em;
    background: transparent;
    white-space: unset;
}

#footer_nav .container > ul > li > .dropdown-menu > li > a:hover {
    text-decoration: underline;
    background: transparent;
    box-shadow: none;
}

/*-----------------------------------------------------------------------------------------------*/
#footer {
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 300;
    font-size: 0.9em;
    line-height: 1.55em;
    font-family: AvantGarde, HelveticaNeue, Arial, Helvetica, Sans-Serif;
}

#footer p {
    margin: 0px;
}

#footer a {
    color: #000000;
}

#footer .logos {
    float: right;
}

#footer .logos > img,
#footer .logos > a {
    display: block;
    float: left;
    margin-left: 30px;
    line-height: 0px;
}

#footer .logos > img:first-child,
#footer .logos > a:first-child {
    margin-left: 0px;
}

.home_latest .module_inline_container a:hover {
    text-decoration: none !important;
}

.home_latest .module_inline_container a:hover h3 {
    color: #5c5c5c;
}

/**/
.hidden_translation {
    height: 0;
    margin: 35px 70px;
    overflow: hidden;
    transition: height 0.4s ease 0s;
    -moz-transition: height 0.4s ease 0s;
    -webkit-transition: height 0.4s ease 0s;
    -ms-transition: height 0.4s ease 0s;
    -o-transition: height 0.4s ease 0s;
}

.hidden_translation.opened {
    height: 1060px;
}

.hidden_translation p {
    font-style: italic;
}

.reveal_translation {
    color: #d31e7d;
    cursor: pointer;
}

.reveal_translation:hover {
    color: #d31e7d;
    text-decoration: none;
}
