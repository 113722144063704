a.skip_to_content {
    position: absolute !important;
    top: 20px;
    left: 20px;
    background: #444;
    color: #fff;
    padding: 10px;
    z-index: 500;
}

.dropdown-menu {
    min-width: 200px;
}

.navbar#navigation {
    margin-bottom: 0px;
    border-left: none;
    border-right: none;
    border-radius: 0px;
    border-top: 0;
    border-bottom: 0;
    position: relative;
    z-index: 10;
    min-height: 38px;
}

.page_home #navigation {
    margin-bottom: 0;
}

.navbar#navigation .navbar-toggle {
    border-color: rgba(255, 255, 255, 0.5);
}

.navbar#navigation .navbar-text {
    color: #fff;
    float: left;
}

.navbar#navigation .down_icon {
    float: right;
    margin-right: 10px;
}

.navbar#navigation .down_icon .glyphicon {
    transition: all 0.5s;
    -ms-transform: rotate(180deg);
        transform: rotate(180deg);
}

.navbar#navigation .collapsed .down_icon .glyphicon {
    -ms-transform: rotate(0deg);
        transform: rotate(0deg);
}

.navbar#navigation .navbar-toggle .icon-bar {
    background-color: #FFFFFF;
}

.navbar#navigation .navbar-collapse#navigation-content {
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 0px;
    padding-right: 0px;
}

.navbar#navigation #navigation-content > ul > li {
    margin-left: 1px;
}

.navbar#navigation #navigation-content > ul > li:first-child {
    margin-left: 0px;
}

.navbar#navigation #navigation-content > ul > li:before {
    position: absolute;
    display: block;
    top: 50%;
    margin-top: -9px;
    left: -1px;
    width: 1px;
    height: 18px;
    background-color: #000000;
    content: "";
}

.navbar#navigation #navigation-content > ul > li:first-child:before,
.navbar#navigation #navigation-content > ul > li:hover:before,
.navbar#navigation #navigation-content > ul > li.active:before,
.master_whats_on .navbar#navigation #navigation-content > ul > li.li_nav_whats_on:before,
.navbar#navigation #navigation-content > ul > li:hover + li:before,
.navbar#navigation #navigation-content > ul > li.active + li:before,
.master_whats_on .navbar#navigation #navigation-content > ul > li.li_nav_whats_on + li:before {
    display: none !important;
}

.navbar#navigation #navigation-content > ul > li > a {
    padding-top: 0px;
    padding-bottom: 0px;
    color: #000000;
    text-shadow: none;
    background-color: transparent;
    margin-left: 1px;
    padding-left: 17px;
    padding-right: 17px;
    font-weight: 300;
    font-size: 1.21em;
    line-height: 38px;
    font-family: AvantGarde, HelveticaNeue, Arial, Helvetica, Sans-Serif;
}

.navbar#navigation #navigation-content > ul > li:first-child > a {
    margin-left: 0px;
    padding-left: 15px;
}

.navbar#navigation #navigation-content > ul > li > a .glyphicon-menu-down {
    display: none;
}

.navbar#navigation #navigation-content > ul > li > a:hover,
.navbar#navigation #navigation-content > ul > li:hover > a,
.navbar#navigation #navigation-content > ul > li.activated > a,
.navbar#navigation #navigation-content > ul > li.maintain_hover > a {
    color: #FFFFFF;
    background-color: #D31E7D;
}

.navbar#navigation #navigation-content > ul > li.active > a,
.master_whats_on .navbar#navigation #navigation-content > ul > li.li_nav_whats_on > a {
    color: #FFFFFF;
    background-color: #D31E7D;
}

.navbar a .glyphicon {
    font-size: 0.8em;
}

.navbar a .glyphicon-triangle-bottom,
.navbar a .glyphicon-menu-down {
    margin-top: 3px;
    margin-right: -10px;
    padding-left: 5px;
}

.navbar a .glyphicon-triangle-right,
.navbar a .glyphicon-chevron-right {
    position: absolute;
    right: 4.5px;
    top: 50%;
    margin-top: -5.5px;
}

.navbar#navigation #navigation-content li.dropdown.right-menu ul.dropdown-menu li.dropdown a .glyphicon-chevron-right {
    right: auto;
    left: 4.5px;
}

.navbar#navigation #navigation-content li.dropdown.right-menu ul.dropdown-menu li.dropdown a .glyphicon-chevron-right:before {
    content: "\e079";
}

/*--------------------------Second tier--------------------------*/
.dropdown-menu {
    left: 1px;
}

.navbar#navigation #navigation-content li.dropdown:hover ul.dropdown-menu,
.navbar#navigation #navigation-content li.dropdown.maintain_hover ul.dropdown-menu {
    display: block;
    box-shadow: none;
    border: 0;
    border-radius: 0px;
}

.navbar#navigation #navigation-content ul.dropdown-menu > li > a {
    padding: 5px 17px 4px 17px;
    position: relative;
    color: #FFFFFF !important;
    white-space: normal;
    font-weight: 300;
    font-size: 1.21em;
    line-height: 1.4em;
    font-family: AvantGarde, HelveticaNeue, Arial, Helvetica, Sans-Serif;
}

.navbar#navigation #navigation-content ul.dropdown-menu > li > a .glyphicon-triangle-bottom,
.navbar#navigation #navigation-content ul.dropdown-menu > li > a .glyphicon-chevron-right,
.navbar#navigation #navigation-content ul.dropdown-menu > li > a .glyphicon-menu-down {
    display: none;
}

.navbar#navigation #navigation-content ul.dropdown-menu > li.active > a {
    background: transparent;
}

.navbar#navigation #navigation-content ul.dropdown-menu > li > a:hover,
.navbar#navigation #navigation-content ul.dropdown-menu > li:hover > a {
    background: #A71B64;
}

/*--------------Hybrid menu------------------*/
.navbar#navigation #navigation-content ul.dropdown-menu {
    width: 230px;
    background-color: #D31E7D;
}

.navbar#navigation #navigation-content ul.dropdown-menu.has_megamenu {
    /*min-height: 310px;*/
    border-radius: 0px;
    border-top: 0;
    padding-bottom: 10px;
}

.navbar#navigation #navigation-content ul.dropdown-menu {
    padding-top: 0;
    padding-bottom: 0;
}

.navbar#navigation #navigation-content ul.dropdown-menu.has_megamenu li.dropdown.mega_dropdown {
    position: static;
}

.navbar#navigation #navigation-content ul.dropdown-menu.has_megamenu li.dropdown .hybrid_mega_dropdown {
    box-shadow: none;
}

/*--------------------------Third tier--------------------------*/
.navbar#navigation #navigation-content ul.dropdown-menu li.dropdown .sub-menu {
    left: 100%;
    position: absolute;
    top: 0;
    visibility: hidden;
    border: 0;
    margin-top: 0;
    padding-top: 0px;
    color: #FFFFFF;
    background: #A71B64;
}

.navbar#navigation #navigation-content li.dropdown.right-menu .sub-menu {
    left: auto;
    right: 100%;
}

.navbar#navigation #navigation-content li.dropdown.right-menu > ul {
    left: auto;
    right: 0;
}

.navbar#navigation #navigation-content ul.dropdown-menu li.dropdown:hover .sub-menu,
.navbar#navigation #navigation-content ul.dropdown-menu li.dropdown.maintain_hover .sub-menu {
    visibility: visible;
}

.navbar#navigation #navigation-content ul.dropdown-menu li.dropdown.block_hover .sub-menu {
    visibility: hidden;
}

/*--------------Hybrid menu------------------*/
.navbar#navigation #navigation-content > ul > li ul.dropdown-menu .hybrid_mega_dropdown hr {
    margin: 0;
}

.navbar#navigation #navigation-content > ul > li ul.dropdown-menu .hybrid_mega_dropdown p {
    margin-top: 0;
    margin-bottom: 5px;
}

.navbar#navigation #navigation-content > ul > li ul.dropdown-menu .hybrid_mega_dropdown h2 {
    font-size: 1.8em;
    margin-top: 0;
    margin-bottom: 5px;
}

.navbar#navigation #navigation-content > ul > li ul.dropdown-menu > li:first-child {
    border-top: 0;
}

.navbar#navigation #navigation-content > ul > li.li_nav_virtual_museum ul.dropdown-menu .hybrid_mega_dropdown .hybrid_title,
.navbar#navigation #navigation-content > ul > li.li_nav_virtual_museum ul.dropdown-menu .hybrid_mega_dropdown .hybrid_links {
    padding-right: 20px;
}

.navbar#navigation #navigation-content > ul > li.li_nav_virtual_museum ul.dropdown-menu .hybrid_mega_dropdown .hybrid_image {
    display: none;
}

ul.dropdown-menu li.dropdown:first-child .sub-menu.hybrid_mega_dropdown {
    visibility: visible;
}

.navbar#navigation #navigation-content ul.dropdown-menu li.dropdown .sub-menu.hybrid_mega_dropdown {
    position: absolute;
    display: block;
    left: 100%;
    top: 0px;
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 16px;
    padding-bottom: 0;
    min-width: 310px;
    min-height: 310px;
    height: 100%;
    width: auto;
    border-radius: 0;
    border: 0;
}

.navbar#navigation #navigation-content ul.dropdown-menu li.dropdown .sub-menu.hybrid_mega_dropdown.hasImage {
    width: 700px;
}

.navbar#navigation #navigation-content li.dropdown.right-menu ul.dropdown-menu li.dropdown .sub-menu.hybrid_mega_dropdown {
    left: auto;
    right: 100%;
}

.navbar#navigation #navigation-content li.dropdown.right-menu ul.dropdown-menu {
    text-align: right;
}

.hybrid_mega_dropdown .hybrid_title {
    margin-left: 20px;
    margin-right: 20px;
    font-size: 1.1em;
    line-height: 1.4em;
    border-bottom: 1px solid #BD548B;
}

.hybrid_mega_dropdown .hybrid_title h3 {
    margin: 0px 0px 8px 0px !important;
    color: #FFFFFF;
    text-transform: none;
    font-weight: 300;
    font-size: 2em;
    line-height: 1.1em;
    font-family: AvantGarde, HelveticaNeue, Arial, Helvetica, Sans-Serif;
}

.hybrid_mega_dropdown .hybrid_title p {
    margin-top: 8px !important;
}

.hybrid_mega_dropdown .hybrid_links {
    margin-top: 6px;
    padding-left: 5px;
}

.hybrid_mega_dropdown .hybrid_links a {
    font-weight: 300;
    font-size: 1.1em;
    line-height: 1.4em;
    font-family: AvantGarde, HelveticaNeue, Arial, Helvetica, Sans-Serif;
}

.hasImage .hybrid_title {
    margin-right: 205px;
}

.hasImage .hybrid_links {
    padding-right: 205px;
}

.hybrid_mega_dropdown .hybrid_links ul.list-unstyled li {
    margin: 8px 0px 8px 0px;
}

.hybrid_mega_dropdown .hybrid_links ul.list-unstyled li a {
    margin: 10px 0;
    color: inherit;
}

.hybrid_mega_dropdown .hybrid_image,
.hybrid_mega_dropdown .hybrid_youtube {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border-bottom-right-radius: 4px;
    overflow: hidden;
}

/*--------------------------Sidebar------------------------*/
.side_menu,
.side_menu ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.side_menu ul {
    display: none;
}

.side_menu li:first-child {
    border-top: 0;
}

.side_menu li {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.side_menu li a {
    display: block;
    padding: 6px 10px;
    font-size: 1.05em;
    text-decoration: none;
    color: #444;
}

.side_menu li a:hover {
    background: transparent url("../images/rgba0-0-0-0.2.png");
    background: rgba(0, 0, 0, 0.2);
    -webkit-filter: none;
            filter: none;
}

.side_menu > li.active > a {
    background: transparent url("../images/rgba0-0-0-0.4.png");
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
}

.side_menu ul > li > a {
    white-space: normal;
}

.side_menu ul li a {
    margin: 0;
    color: #666;
    font-size: 0.9em;
    padding: 3px 10px 3px 20px;
}

.side_menu ul ul li a {
    padding-left: 30px;
}

.side_menu ul ul ul li a {
    padding-left: 40px;
}

.side_menu ul ul ul ul li a {
    padding-left: 50px;
}

.side_menu ul ul ul ul ul li a {
    padding-left: 60px;
}

.side_menu ul ul ul ul ul ul li a {
    padding-left: 70px;
}

.side_menu ul li {
    border-top: 0 !important;
}

.side_menu ul li a:focus {
    background: transparent url("../images/rgba0-0-0-0.3.png");
    background: rgba(0, 0, 0, 0.3);
    -webkit-filter: none;
            filter: none;
}

.side_menu .active ul li.active > a {
    font-weight: bold;
}

.side_menu li.active > ul {
    display: block !important;
}

.dropdown-menu.sub-menu li.active a {
    color: #ffffff;
    font-weight: 500;
}

/*--------------------------Admin--------------------------*/
#admin_nav {
    position: fixed;
    z-index: 2000;
    top: 0px;
    left: 0px;
    padding: 6px 0px;
    width: 100%;
    background-color: #000000;
}

#admin_nav + header {
    margin-top: 54px;
}

#admin_nav .nav-pills,
#admin_nav .btn-group {
    float: left;
}

#admin_nav .btn-group {
    margin-top: 3px;
    margin-left: 14px;
}

#admin_nav .message {
    display: block;
    float: left;
    margin-top: 3px;
    margin-left: 7px;
    color: #FFFFFF;
    height: 34px;
    line-height: 34px;
}
