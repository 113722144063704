.carousel {
    margin-bottom: 0;
    padding: 0 40px 0 40px;
}

.carousel-inner .thumbnail {
    margin-bottom: 0;
}

.carousel-control {
    left: -12px;
}

.carousel-control.right {
    right: -12px;
}

.carousel-control {
    position: absolute;
    top: 50%;
    width: 40px;
    height: 40px;
    font-size: 28px;
    line-height: 40px;
    margin-top: -20px;
    color: #ffffff;
    text-align: center;
    background: none !important;
    text-shadow: none;
    opacity: 0.5;
    filter: alpha(opacity=50);
}

.carousel-control:hover,
.carousel-control:focus,
.carousel-control:active {
    color: #000;
}
