.collections_category_list {
    margin-left: 0px;
    padding-left: 0px;
    color: #616161;
}

.collections_category_list li {
    margin-left: 16px;
    padding-left: 16px;
}

.collection_boxes {
    margin-top: 30px;
}

.collection_boxes .collection_box {
    margin-bottom: 30px;
}

.collection_boxes .collection_box:nth-child(3n+0) + .collection_box {
    clear: both;
}

.collection_boxes .box_image {
    margin-bottom: 8px;
    height: 110px;
    overflow: hidden;
}

.collection_boxes .box_image a {
    display: block;
    line-height: 0px;
    text-align: center;
}

.collection_boxes .box_image a img {
    min-width: 100%;
    min-height: 100%;
}

.collection_boxes .collection_box > h2 {
    margin-top: 0px;
    margin-bottom: 8px;
}

.collection_boxes .collection_box > h2 a:hover {
    color: #666666;
    text-decoration: none;
}

.collection_boxes .collection_box > p {
    margin-top: 8px;
    line-height: 1.35em;
}
