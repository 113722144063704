h2.media-heading + p {
    margin-top: 0px;
}

.right_image img {
    width: 100%;
    height: auto;
}

/*-----------------------------------------------*/
.social_share {
    color: #999;
    white-space: nowrap;
    display: inline-block;
}

.share_buttons {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: inline;
}

.share_buttons li {
    margin: 0;
    margin-left: 2px;
    display: inline;
}

/*-----------------------------------------------*/
.module_featured_image {
    height: 345px;
    overflow: hidden;
    text-align: center;
}

.module_featured_image a {
    display: block;
    height: 100%;
    line-height: 0px;
}

.module_featured_image a img,
body.responsive .module_featured_image a img {
    width: auto;
    max-width: none !important;
    height: 100% !important;
}

.module_featured_title {
    margin: 0px;
    color: #2F6793;
    text-transform: none;
    font-weight: 300;
    font-size: 2.9em;
    line-height: 1.15em;
    font-family: AvantGarde, HelveticaNeue, Arial, Helvetica, Sans-Serif;
}

.module_featured_metadata {
    margin: 20px 0px;
    color: #2692BF;
    font-size: 1.6em;
    line-height: 1.2em;
}

.module_featured_read_more a {
    color: #E87B2A !important;
}

/*-----------------------------------------------*/
.module_inline_image {
    float: left;
    margin-top: 3px;
    margin-right: 15px;
    margin-bottom: 20px;
    width: 100px;
    height: 100px;
    overflow: hidden;
    text-align: center;
}

.module_inline_image a {
    display: block;
    height: 100%;
    line-height: 0px;
}

.module_inline_image a img {
    width: auto;
    max-width: none !important;
    height: 100% !important;
}

h3.module_inline_title {
    margin: 0 0 0 5px;
    color: #21435D;
    text-transform: none;
    font-weight: 300;
    font-size: 1.4em;
    line-height: 1.25em;
    font-family: AvantGarde, HelveticaNeue, Arial, Helvetica, Sans-Serif;
}

h3.module_inline_title a {
    color: #21435D;
}

.module_inline_metadata {
    margin: 4px 0px 8px 0px;
    color: #21435D;
    font-size: 1.1em;
    line-height: 1.35em;
}

.module_inline_content {
    margin: 0px 0px 20px 0px;
    line-height: 1.2em;
}

.module_inline_content a {
    color: #E87B2A !important;
}

.module_inline_rss {
    display: block;
    float: right;
    margin-top: 3px;
    line-height: 0px;
}

.module_inline_options {
    margin: 0px;
    margin-bottom: 0px;
    text-align: center;
}

.module_inline_options a {
    color: #E87B2A;
}

/*-----------------------------------------------*/
.events_inline_margin {
    margin-top: 70px;
}

/*-----------------------------------------------*/
.module_categories {
    margin-top: 30px;
}

.module_categories > span {
    display: block;
    float: left;
    margin-right: 12px;
    margin-top: 12px;
    padding-right: 8px;
    color: #000000;
    font-weight: 300;
    font-size: 1.21em;
    line-height: 30px;
    font-family: AvantGarde, HelveticaNeue, Arial, Helvetica, Sans-Serif;
}

.module_categories ul {
    display: block;
    float: left;
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

.module_categories ul li {
    display: block;
    float: left;
    margin: 0px;
    padding: 0px;
}

.module_categories ul li a {
    display: block;
    float: left;
    margin-right: 12px;
    margin-top: 12px;
    padding: 0px 8px;
    color: #FFFFFF;
    text-decoration: none;
    font-weight: 300;
    font-size: 1.21em;
    line-height: 30px;
    font-family: AvantGarde, HelveticaNeue, Arial, Helvetica, Sans-Serif;
    background-color: #909090;
}

.module_categories ul li:last-child a {
    margin-right: 0px;
}

.module_categories ul li a:hover {
    background-color: #B0B0B0;
}

.module_categories ul li.active a {
    background-color: #D31E7D;
}

/*-----------------------------------------------*/
.module_listing_row + .clearer:last-child {
    margin-bottom: 30px;
}

.module_listing_container {
    margin-top: 30px;
}

.module_listing_container > div {
    background-color: #E8E8E8;
}

.module_listing_image {
    position: relative;
    height: 159px;
    overflow: hidden;
}

.module_listing_image a {
    display: block;
    height: 100%;
    text-align: center;
    line-height: 0px;
}

.module_listing_image a img {
    max-width: none !important;
    min-width: 100%;
    min-height: 100%;
}

.module_listing_categories {
    position: absolute;
    bottom: 0px;
    left: 0px;
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

.module_listing_categories li {
    display: block;
    float: left;
    margin-right: 12px;
    padding: 0px 8px;
    color: #FFFFFF;
    text-decoration: none;
    font-weight: 300;
    font-size: 1.21em;
    line-height: 30px;
    font-family: AvantGarde, HelveticaNeue, Arial, Helvetica, Sans-Serif;
    background-color: #909090;
}

.module_listing_title {
    margin: 0px;
    padding: 10px 15px;
    color: #FFFFFF;
    font-weight: normal;
    font-size: 1.5em;
    line-height: 1.2em;
    font-family: HelveticaNeue, Arial, Helvetica, Sans-Serif;
    background-color: #8FAA14;
    text-transform: capitalize;
}

.module_listing_title a {
    color: #FFFFFF !important;
}

.module_listing_title a:hover {
    text-decoration: none;
}

.module_listing_content {
    padding: 10px 15px 20px 15px;
    color: #000000;
    font-size: 0.9em;
    line-height: 1.6em;
    background-color: #E8E8E8;
}

.module_listing_content .module_listing_metadata {
    margin: 0px 0px 6px 0px;
    color: #D31E7D;
    font-size: 1.6666em;
    line-height: 1.2em;
}

.module_listing_content p {
    margin: 0px;
}

/*-----------------------------------------------*/
/* Calendar */
.calendar {
    margin: 15px 0;
}

.calendar_day_label {
    font-weight: bold;
    color: #999;
}

.calendar_week {
    clear: both;
}

.calendar_day {
    float: left;
    width: 14.28%;
}

.other_month.calendar_day {
    opacity: 0.6;
}

.calendar_day_today {
    background: #f7ebb3;
}

.calendar_day_inner {
    position: relative;
    border: 1px solid #ddd;
    padding: 7px;
    margin: 1px;
    min-height: 100px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.calendar_day_number {
    position: absolute;
    top: 7px;
    right: 7px;
    color: #666;
}

.calendar_day_saturday .calendar_day_inner,
.calendar_day_sunday .calendar_day_inner {
    background: #efefef;
}

/*-----------------------------------------------*/
/* Data Pager */
.module_listing_row + .pagination {
    display: block;
    float: right;
    margin-bottom: 0px;
}

.module_listing_row + .pagination li {
    position: relative;
}

.module_listing_row + .pagination > li:first-child:before {
    display: inline;
    float: left;
    content: "Page";
    padding: 6px 0px 0px 0px;
    color: #D31E7D;
    line-height: 1.42857143;
}

.module_listing_row + .pagination .data_pager_previous,
.module_listing_row + .pagination .data_pager_next {
    display: none;
}

.module_listing_row + .pagination .data_pager_numeric {
    margin-left: 0px;
    padding: 6px 8px 0px 11px;
    color: #000000;
    background-color: transparent !important;
    border: none;
}

.module_listing_row + .pagination .data_pager_numeric:hover {
    text-decoration: underline;
}

.module_listing_row + .pagination .data_pager_current {
    color: #D31E7D !important;
}

.module_listing_row + .pagination .data_pager_current:hover {
    text-decoration: none;
}

.module_listing_row + .pagination li + li:before {
    position: absolute;
    display: block;
    top: 4px;
    left: 0px;
    content: "|";
    color: #000000;
}

.module_listing_row + .pagination li:first-child + li:before,
.module_listing_row + .pagination li:last-child:before {
    display: none;
}

.data_pager a,
.data_pager .data_pager_button {
    padding: 5px;
    background-color: #eef;
    border: 1px solid #bbb;
    margin: 0 2px;
}

.data_pager .data_pager_first {
    margin-left: 0;
    background-color: transparent;
    border: 0;
}

.data_pager .data_pager_last {
    margin-right: 0;
    background-color: transparent;
    border: 0;
}

.data_pager .data_pager_current {
    background-color: #f9f9f9;
}

.data_pager a.aspNetDisabled.data_pager_button {
    text-decoration: none;
    color: inherit;
}

/*-----------------------------------------------*/
.module_article_container {
    padding-top: 24px;
    margin-bottom: 40px;
}

.module_article_sidebar p {
    margin-bottom: 30px;
}

.module_article_sidebar img {
    max-width: 100%;
    height: auto !important;
}

.module_article_sidebar > p:first-child > img:first-child {
    margin-top: 4px;
}

.module_article_sidebar p:last-child {
    margin-bottom: 0px;
}

.module_article_social {
    position: absolute;
    bottom: 100%;
    right: 15px;
    margin-bottom: 26px;
}

.module_article_title {
    margin-top: 0px;
    margin-bottom: 20px;
    color: #D31E7D;
    font-family: HelveticaNeue, Arial, Helvetica, Sans-Serif;
    text-transform: capitalize;
}

.module_article_metadata {
    color: #8FAA14;
    font-size: 1.71em !important;
    line-height: 1.2em;
}

/*-----------------------------------------------------------------------------------------------*/
/*-REVIEWS START-*/
.review_box {
    background: #eff1f7;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2VmZjFmNyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: linear-gradient(top, #eff1f7 0%, #ffffff 100%);
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
}

.review_box .col {
    padding-top: 10px;
    padding-bottom: 10px;
}

.review_box .review_name {
    font-size: 1.2em;
    padding-top: 3px;
    display: block;
}

.review_box .review_number a {
    color: #A2A2A2;
}

.review_box .review_date {
    font-size: 0.8em;
    color: #8B867A;
}

.review_box .review_comment {
    margin: 7px 0;
    font-size: 0.9em;
    line-height: 1.4em;
}

.review_box blockquote, blockquote {
    margin: 1em 3em;
    color: #999;
    border-left: 2px solid #999;
    padding-left: 1em;
    font-style: italic;
}

.review_box blockquote .quote_name, blockquote .quote_name {
    font-style: normal;
    color: #777;
}

.review_box .review_rating {
    float: right;
    font-weight: bold;
}

.review_box .review_rate {
    float: right;
    display: block;
    padding-right: 15px;
}

.review_box .quoteLink {
    float: right;
    font-size: 0.8em;
    background: url("../images/quote.png") center right no-repeat;
    padding-right: 15px;
    color: #A2A2A2;
}

.review_box .review_rating_good {
    color: #67A851;
}

.review_box .review_rating_neutral {
    color: #8B867A;
}

.review_box .review_rating_bad {
    color: #CF2929;
}

/*-REVIEWS END-*/
/*-----------------------------------------------------------------------------------------------*/
/* TABLES */
.tabledefault {
    width: 100%;
    border-collapse: collapse;
}

.tabledefault th {
    padding: 3px;
    font-weight: bold;
    background-color: #DFDFDF;
    border-bottom: solid 2px #CCCCCC;
    border-right: solid 1px #CCCCCC;
}

.tabledefault tr:hover td {
    background: #DFDFDF;
}

.tabledefault td {
    padding: 8px;
    background-color: #EFEFEF;
    border-bottom: 1px solid #FFFFFF;
    border-top: 1px solid transparent;
}

/*-----------------------------------------------------------------------------------------------*/
