/*-----------------------------------------------------------------------------------------------*/
/* FORMS */
.validationError,
.validationError .help-block {
    color: #C30000;
}

.validationSummary {
    border: 1px solid #760000;
    margin-bottom: 20px;
}

.validationSummaryHeader {
    background: #C30000;
    padding: 5px;
    color: #fcfcfc;
    font-weight: bold;
}

.validationSummary ul li {
    margin: 5px 0;
    padding: 0;
}

.validationSummary a {
    color: #C30000;
    text-decoration: underline;
}

.validationSummary a:hover {
    text-decoration: none;
}

body a.btn-default {
    color: #444;
}

.hon3yp0t {
    display: none;
}

.h0n3yp0t.hpotcontainer .h0ney {
    position: absolute;
    left: -999999px;
    top: -9999999px;
}

.form-group.has-feedback .form-control-feedback {
    top: 25px;
}

.input-group.date + span .glyphicon-remove {
    display: none;
}

.form-group .help-block {
    margin-bottom: 0px;
    font-size: 0.9em;
    line-height: 1.4em;
}

.form-group .checkbox label {
    font-weight: 300;
    font-size: 0.9em;
    line-height: 1.4em;
}

.form-group .checkbox .validationError {
    color: #A94442;
}

.form-group fieldset p {
    margin: 0px 0px 10px;
}

.form-group input[type=checkbox],
.form-group input[type=radio] {
    margin-right: 5px;
}

.checkboxes {
    display: block;
    width: 100%;
    padding: 6px 12px 0 6px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

.checkboxes label {
    padding-right: 10px;
    font-weight: normal;
}
