.modSearchBar {
    padding: 15px;
    background: #f1f1f1;
    border-radius: 4px;
}

.modSearchBar .search_icon {
    padding-left: 6px;
    padding-right: 6px;
}

.modSearchBar .modSearchFilter td span input {
    margin-right: 5px;
}

.modSearchBar .modSearchFilter td span label {
    margin-right: 10px;
}

.search_result {
    margin-bottom: 20px;
}

/*--------------------------------------------------------------*/
.search_layer {
    position: fixed;
    display: none;
    z-index: 1000;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background: rgba(0, 0, 0, 0.9);
    z-index: 1031;
}

.search_layer .search_close {
    position: absolute;
    display: block;
    top: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    color: #FFFFFF;
    text-align: center;
    text-decoration: none;
    font-size: 2.2em;
    line-height: 50px;
    background-color: #D31E7D;
    transition: background-color, 0.3s;
}

.search_layer .search_close:hover {
    background-color: #F33E9D;
}

.search_layer .search_close .glyphicon {
    position: static !important;
    display: inline;
    top: 0px;
    margin: 0px !important;
    line-height: 50px !important;
}

.search_layer .search_box {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -200px;
    margin-left: -250px;
    width: 500px;
    height: 150px;
}

.search_layer .search_box h2 {
    margin-bottom: 30px;
    color: #D31E7D;
    font-weight: 300;
    font-size: 3.4em;
    line-height: 1em;
    font-family: AvantGarde, HelveticaNeue, Arial, Helvetica, Sans-Serif;
}

.search_layer .search_box .input-group .form-control,
.search_layer .search_box .input-group-btn > .btn {
    font-size: 24px;
    border: none;
    border-radius: 0px;
}

.search_layer .search_box .input-group-btn > .btn {
    margin: 0px;
    color: #FFFFFF;
    text-shadow: none;
    background-color: #D31E7D;
    background-image: none;
    transition: background-color, 0.3s;
    box-shadow: none;
}

.search_layer .search_box .input-group-btn > .btn:hover {
    background-color: #F33E9D;
}
