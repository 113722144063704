.page_home .info_boxes {
    margin-bottom: -45px;
}

.info_box {
    position: relative;
    margin-bottom: 30px;
    padding: 15px 15px 40px 15px;
    line-height: 1.3em;
}

.info_box.red {
    background-color: #F05574;
}

.info_box.blue {
    background-color: #2692BF;
}

.info_box.pink {
    background-color: #D31E7D;
}

.info_box.green {
    background-color: #8EAB0E;
}

.info_box.lilac {
    background-color: #717EBD;
}

.info_box.orange {
    background-color: #E87B2A;
}

.info_box,
.info_box h2,
.info_box a {
    color: #FFFFFF;
}

.info_box .box_image {
    overflow: hidden;
    margin-top: -15px;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 15px;
    height: 232px;
}

.info_box .box_image img {
    width: auto;
    height: auto;
    min-width: 100%;
}

.info_box h2 {
    margin-bottom: -6px;
    font-weight: 300;
    font-size: 2.2em;
    line-height: 1.1em;
    font-family: AvantGarde, HelveticaNeue, Arial, Helvetica, Sans-Serif;
}

.info_box ul {
    margin-left: 15px;
    padding-left: 0px;
}

.info_box ul li {
    margin-left: 0px;
    padding-left: 0px;
}

.info_box table td {
    padding-left: 10px;
    padding-bottom: 10px;
}

.info_box table td:first-child {
    padding-left: 0px;
}

.info_box a.read_more {
    background-color: #65696E;
    bottom: 15px;
    color: #ffffff;
    display: block;
    font-size: 1.1em;
    line-height: 1em;
    padding: 5px 10px;
    position: absolute;
    right: 15px;
}
