body.responsive {
    /*body { min-width: 0; }*/
    /*-----------------------------------------------------------------------------------------------*/
}

body.responsive .footer_responsive_button {
    display: none;
}

body.responsive .responsive_shown {
    display: block;
}

@media screen and (min-width: 1200px) {
    body.responsive .spacer_lg {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    body.responsive .spacer_top_lg {
        margin-top: 15px;
    }
    body.responsive .spacer_bottom_lg {
        margin-bottom: 15px;
    }
    body.responsive .padder_lg {
        padding-left: 15px;
        padding-right: 15px;
    }
    body.responsive .padder_right_lg {
        padding-right: 15px;
    }
    body.responsive .padder_left_lg {
        padding-left: 15px;
    }
    body.responsive .nopadding_lg {
        padding: 0 !important;
    }
    body.responsive .nopadding_right_lg {
        padding-right: 0 !important;
    }
    body.responsive .nopadding_left_lg {
        padding-left: 0 !important;
    }
    body.responsive .push_right_lg {
        float: right;
    }
    body.responsive .push_left_lg {
        float: left;
    }
    body.responsive .btn_block_lg {
        display: block;
        float: none;
    }
    body.responsive a.link_block_lg {
        display: block;
        padding: 8px 0;
        margin-top: 5px;
        border-top: 1px solid #d8d8d8;
        border-bottom: 1px solid #d8d8d8;
        text-decoration: none;
        padding-left: 15px;
        padding-right: 25px;
        position: relative;
    }
    body.responsive a.link_block_lg:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
}

@media screen and (min-width: 992px) {
    body.responsive .nopadding_md {
        padding: 0 !important;
    }
    body.responsive .nopadding_right_md {
        padding-right: 0 !important;
    }
    body.responsive .nopadding_left_md {
        padding-left: 0 !important;
    }
    body.responsive .push_right_md {
        float: right;
    }
    body.responsive .push_left_md {
        float: left;
    }
}

@media screen and (min-width: 768px) {
    body.responsive .nopadding_sm {
        padding: 0 !important;
    }
    body.responsive .nopadding_right_sm {
        padding-right: 0 !important;
    }
    body.responsive .nopadding_left_sm {
        padding-left: 0 !important;
    }
    body.responsive .push_right_sm {
        float: right;
    }
    body.responsive .push_left_sm {
        float: left;
    }
}

@media screen and (max-width: 0) {
    body.responsive .nopadding_xs {
        padding: 0 !important;
    }
    body.responsive .nopadding_right_xs {
        padding-right: 0 !important;
    }
    body.responsive .nopadding_left_xs {
        padding-left: 0 !important;
    }
    body.responsive .push_right_xs {
        float: right;
    }
    body.responsive .push_left_xs {
        float: left;
    }
}

@media screen and (max-width: 1199px) {
    body.responsive {
        padding-top: 205px !important;
        /*.prompt > img {
            margin-right: 14px;
        }

        .prompt .icons {
            margin-right: 10px;
        }

        .prompt h3 {
            font-size: 1.2em;
        }

        .prompt p {
            padding-left: 66px;
        }

        .lower_prompts .prompt {
            margin-bottom: 30px;
        }*/
    }
    body.responsive .hidden_translation.opened {
        height: 1500px;
    }
    body.responsive #header .header_nav ul li a:before {
        top: 2px;
    }
    body.responsive #header .header_nav ul li a {
        padding: 6px 12px;
        font-size: 1.1em;
    }
    body.responsive #header .header_strapline {
        font-size: 1.5em;
    }
    body.responsive .navbar#navigation {
        min-height: 32px;
    }
    body.responsive .navbar#navigation #navigation-content > ul > li:before {
        margin-top: -7px;
        height: 14px;
    }
    body.responsive .navbar#navigation #navigation-content > ul > li > a {
        padding-left: 9px;
        padding-right: 9px;
        font-size: 1.1em;
        line-height: 32px;
    }
    body.responsive .navbar#navigation #navigation-content ul.dropdown-menu.has_megamenu {
        width: 200px;
    }
    body.responsive .navbar#navigation #navigation-content ul.dropdown-menu > li > a {
        padding-left: 9px;
        padding-right: 9px;
        font-size: 1.1em;
    }
    body.responsive .navbar#navigation #navigation-content ul.dropdown-menu li.dropdown .sub-menu.hybrid_mega_dropdown.hasImage {
        width: 500px;
    }
    body.responsive .hybrid_mega_dropdown .hybrid_links a {
        font-size: 1.1em;
    }
    body.responsive .hasImage .hybrid_title {
        margin-right: 20px;
    }
    body.responsive .hasImage .hybrid_links {
        padding-right: 20px;
    }
    body.responsive .hybrid_mega_dropdown .hybrid_image,
    body.responsive .hybrid_mega_dropdown .hybrid_youtube {
        display: none;
    }
    body.responsive .spacer_md {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    body.responsive .spacer_top_md {
        margin-top: 15px;
    }
    body.responsive .spacer_bottom_md {
        margin-bottom: 15px;
    }
    body.responsive .padder_md {
        padding-left: 15px;
        padding-right: 15px;
    }
    body.responsive .padder_right_md {
        padding-right: 15px;
    }
    body.responsive .padder_left_md {
        padding-left: 15px;
    }
    body.responsive .btn_block_md {
        display: block;
        float: none;
    }
    body.responsive a.link_block_md {
        display: block;
        padding: 8px 0;
        margin-top: 5px;
        border-top: 1px solid #d8d8d8;
        border-bottom: 1px solid #d8d8d8;
        text-decoration: none;
        padding-left: 15px;
        padding-right: 25px;
        position: relative;
    }
    body.responsive a.link_block_md:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
    body.responsive .home_latest h2.latest span {
        padding-left: 0px;
        padding-top: 20px;
        padding-bottom: 4px;
        display: block;
    }
    body.responsive .home_latest h2.upcoming {
        line-height: 1.15em;
    }
    body.responsive .contact_prompt {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    body.responsive .contact_prompt p {
        font-size: 1.5em;
        line-height: 1.3em;
    }
    body.responsive .home_intro h1 + h2 {
        margin-top: -8px;
    }
    body.responsive .lower_banner img {
        height: 200px;
    }
    body.responsive .lower_banner p {
        font-size: 3em;
    }
    body.responsive .lower_nav {
        margin-bottom: 60px;
    }
    body.responsive .lower_nav ul li a {
        font-size: 1.1em;
    }
    body.responsive .prompt .prompt_icon,
    body.responsive .prompt .icons {
        float: none;
    }
    body.responsive .prompt .prompt_text {
        float: none;
        width: 100%;
    }
    body.responsive #footer_nav {
        font-size: 1em;
    }
    body.responsive #footer {
        font-size: 0.8em;
    }
    body.responsive .info_box .box_image {
        height: 188px;
    }
}

@media screen and (max-width: 1024px) {
    body.responsive {
        padding-top: 0 !important;
    }
}

@media screen and (max-width: 991px) {
    body.responsive {
        -webkit-text-size-adjust: 100%;
        /*-----------------------------------------------------------------------------------------------*/
        /* PRIMARY NAVIGATION */
        /* To override the bootstrap breakpoint for mobile menu */
        /*-----------------------------------------------------------------------------------------------*/
        /*.navbar#navigation .navbar-toggle .icon-bar {
            background-color: #000000;
        }*/
        /*-----------------------------------------------------------------------------------------------*/
    }
    body.responsive #header .logo {
        margin-bottom: 0px;
    }
    body.responsive #header .header_strapline {
        margin-top: 105px;
        max-width: 375px;
    }
    body.responsive .explore_more {
        margin: 60px auto 0;
        position: static !important;
        text-align: center !important;
        width: 50%;
    }
    body.responsive .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    }
    body.responsive .container > .navbar-collapse {
        background-color: #ffffff;
        margin-left: -15px;
        margin-right: -15px;
    }
    body.responsive .navbar-collapse.collapse {
        display: none !important;
        visibility: hidden !important;
    }
    body.responsive .navbar-collapse.in {
        overflow-y: auto !important;
    }
    body.responsive .collapse.in {
        display: block !important;
        visibility: visible !important;
    }
    body.responsive .navbar-toggle {
        display: block !important;
        border: none !important;
    }
    body.responsive .navbar-header,
    body.responsive .navbar-nav,
    body.responsive .navbar-nav > li {
        float: none;
    }
    body.responsive .navbar#navigation #navigation-content > ul > li > a .glyphicon.visible-xs-inline {
        display: inline !important;
    }
    body.responsive .navbar#navigation {
        margin-top: 25px;
    }
    body.responsive .navbar#navigation .container {
        background-color: #7e7e7e;
        width: 100%;
    }
    body.responsive .navbar#navigation .navbar-header {
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 0px;
    }
    body.responsive .navbar#navigation .navbar-toggle {
        margin-left: 5px;
        margin-right: 0px;
        border-width: 1px;
        border-color: #555555;
    }
    body.responsive .navbar#navigation .navbar-collapse#navigation-content {
        padding-left: 15px;
        padding-right: 15px;
    }
    body.responsive .navbar#navigation #navigation-content > ul > li {
        margin-left: 0px;
    }
    body.responsive .navbar#navigation #navigation-content > ul > li:before {
        display: none;
    }
    body.responsive .navbar#navigation #navigation-content > ul > li > a {
        margin-left: 0px;
        margin-bottom: 1px;
        text-align: left;
    }
    body.responsive .navbar#navigation #navigation-content > ul > li .glyphicon {
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -0.5em;
    }
    body.responsive .navbar#navigation #navigation-content > ul {
        margin: 0;
    }
    body.responsive .navbar#navigation #navigation-content li.dropdown ul.dropdown-menu,
    body.responsive .navbar#navigation #navigation-content ul.dropdown-menu li.dropdown .sub-menu.hybrid_mega_dropdown {
        position: static !important;
        float: none;
        min-width: 0px;
        width: 100%;
        height: auto;
        border: none;
        border-radius: 0px;
    }
    body.responsive .navbar#navigation #navigation-content li.dropdown > a {
        position: relative;
    }
    body.responsive .navbar#navigation #navigation-content li.dropdown > a .glyphicon-triangle-bottom,
    body.responsive .navbar#navigation #navigation-content li.dropdown > a .glyphicon-menu-down {
        position: absolute;
        display: inline-block;
        top: 50%;
        right: 20px;
        margin: -6px 0px 0px 0px;
    }
    body.responsive .navbar#navigation #navigation-content li.dropdown > a .glyphicon-triangle-right,
    body.responsive .navbar#navigation #navigation-content li.dropdown > a .glyphicon-chevron-right {
        position: static !important;
    }
    body.responsive .navbar#navigation #navigation-content li.dropdown > a .glyphicon-chevron-right:before {
        content: "\e258";
    }
    body.responsive .navbar#navigation #navigation-content ul.dropdown-menu {
        min-height: 0px;
        padding-left: 12px;
        padding-right: 12px;
        background: #fff !important;
    }
    body.responsive .navbar#navigation #navigation-content > ul > li ul.dropdown-menu > li > a {
        color: #444 !important;
        background: #fff !important;
        border-bottom: 1px solid #ccc;
        padding-left: 0;
    }
    body.responsive .navbar#navigation #navigation-content > ul > li ul.dropdown-menu > li {
        border-top: 0 !important;
    }
    body.responsive .navbar#navigation #navigation-content ul.dropdown-menu > li.dropdown.mega_dropdown:hover > a,
    body.responsive .navbar#navigation #navigation-content ul.dropdown-menu > li.dropdown.mega_dropdown.maintain_hover > a {
        background-color: #c8c8c8;
        background-image: linear-gradient(to bottom, #d5d5d5 0, #c8c8c8 100%);
    }
    body.responsive .navbar#navigation #navigation-content ul.dropdown-menu li.dropdown .sub-menu.hybrid_mega_dropdown .hybrid_links ul a {
        position: relative;
        color: #000000;
        padding-right: 20px;
    }
    body.responsive .navbar#navigation #navigation-content ul.dropdown-menu li.dropdown .sub-menu.hybrid_mega_dropdown .hybrid_links ul a .glyphicon {
        right: 0px;
        color: #444444;
    }
    body.responsive .navbar#navigation #navigation-content .sub-menu.hybrid_mega_dropdown {
        position: static;
        visibility: visible !important;
        background: none !important;
        float: none;
        padding-left: 0px;
        min-width: 0px;
        width: auto !important;
        min-height: 0px;
        height: auto;
        border-right: none;
        border-radius: 0px;
    }
    body.responsive .navbar a .glyphicon-triangle-right,
    body.responsive .navbar a .glyphicon-chevron-right {
        position: static;
    }
    body.responsive .navbar#navigation #navigation-content li.dropdown .mega_dropdown > a .glyphicon-triangle-bottom,
    body.responsive .navbar#navigation #navigation-content li.dropdown .mega_dropdown > a .glyphicon-menu-down {
        display: none;
    }
    body.responsive .navbar#navigation #navigation-content ul.dropdown-menu li.dropdown .sub-menu.hybrid_mega_dropdown {
        width: 100% !important;
    }
    body.responsive .navbar#navigation #navigation-content ul.dropdown-menu li.dropdown:hover .sub-menu,
    body.responsive .navbar#navigation #navigation-content ul.dropdown-menu li.dropdown.maintain_hover .sub-menu {
        display: block;
    }
    body.responsive .navbar#navigation #navigation-content ul.dropdown-menu li.dropdown.block_hover .sub-menu {
        display: none;
    }
    body.responsive .hybrid_mega_dropdown {
        margin-top: 0px !important;
        padding-top: 0px !important;
    }
    body.responsive .navbar#navigation #navigation-content ul.dropdown-menu li.dropdown .sub-menu.hybrid_mega_dropdown {
        min-height: 0px !important;
    }
    body.responsive .hybrid_mega_dropdown .hybrid_title {
        border-bottom: none;
    }
    body.responsive .hybrid_mega_dropdown .hybrid_title h3 {
        display: none;
    }
    body.responsive .hybrid_mega_dropdown .hybrid_title p {
        margin-bottom: 0px;
        padding-bottom: 5px;
        color: #000000;
    }
    body.responsive .hybrid_mega_dropdown .hybrid_title p:last-child {
        border-bottom: 1px solid #BD548B;
    }
    body.responsive .hybrid_mega_dropdown .hybrid_title {
        margin-left: 12px !important;
        margin-right: 12px !important;
    }
    body.responsive .hybrid_mega_dropdown .hybrid_links {
        margin-left: -3px !important;
        margin-right: 12px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    body.responsive .home_latest h2.latest {
        margin-bottom: 20px;
    }
    body.responsive .home_latest h2.latest span {
        padding-bottom: 0px;
    }
    body.responsive .home_latest h2.upcoming {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    body.responsive p.module_inline_options {
        text-align: left;
    }
    body.responsive #page > #content:first-child {
        margin-top: 0px;
    }
    body.responsive .btn_block_sm {
        display: block;
        float: none;
    }
    body.responsive a.link_block_sm {
        display: block;
        padding: 8px 0;
        margin-top: 5px;
        border-top: 1px solid #d8d8d8;
        border-bottom: 1px solid #d8d8d8;
        text-decoration: none;
        padding-left: 15px;
        padding-right: 25px;
        position: relative;
    }
    body.responsive a.link_block_sm:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
    body.responsive .spacer_sm {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    body.responsive .spacer_top_sm {
        margin-top: 15px;
    }
    body.responsive .spacer_bottom_sm {
        margin-bottom: 15px;
    }
    body.responsive .padder_sm {
        padding-left: 15px;
        padding-right: 15px;
    }
    body.responsive .padder_right_sm {
        padding-right: 15px;
    }
    body.responsive .padder_left_sm {
        padding-left: 15px;
    }
    body.responsive .home_intro h1 + h2 {
        font-size: 1.4em;
    }
    body.responsive .lower_nav {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    body.responsive .row.home_prompts {
        margin-bottom: 0px;
    }
    body.responsive .home_prompts .prompt,
    body.responsive .lower_prompts .prompt {
        margin-bottom: 20px;
    }
    body.responsive .events_inline_margin {
        margin-top: 30px;
    }
    body.responsive #footer_nav {
        display: none;
    }
    body.responsive #footer {
        margin-top: 14px;
    }
    body.responsive .info_box .box_image {
        height: 221px;
    }
    body.responsive .collection_boxes .collection_box:nth-child(3n+0) + .collection_box {
        clear: none;
    }
    body.responsive .collection_boxes .collection_box:nth-child(2n+0) + .collection_box {
        clear: both;
    }
    body.responsive .module_listing_row .module_listing_container:nth-child(3n+0) + .module_listing_container {
        clear: none;
    }
    body.responsive .module_listing_row .module_listing_container:nth-child(2n+0) + .module_listing_container {
        clear: both;
    }
}

@media screen and (max-width: 767px) {
    body.responsive {
        /*-----------------------------------------------------------------------------------------------*/
        /* HEADER */
        /*#header .header_nav {
            left: 10px;
            right: auto;
        }*/
        /*-----------------------------------------------------------------------------------------------*/
        /*-----------------------------------------------------------------------------------------------*/
        /* FOOTER */
        /* BANNER */
    }
    body.responsive #cookieStrip {
        height: auto !important;
        padding-bottom: 10px;
    }
    body.responsive .hidden_translation {
        height: 100%;
    }
    body.responsive .btn_block_xs {
        display: block;
        float: none;
    }
    body.responsive .spacer_xs {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    body.responsive .spacer_top_xs {
        margin-top: 15px;
    }
    body.responsive .spacer_bottom_xs {
        margin-bottom: 15px;
    }
    body.responsive .padder_xs {
        padding-left: 15px;
        padding-right: 15px;
    }
    body.responsive .padder_right_xs {
        padding-right: 15px;
    }
    body.responsive .padder_left_xs {
        padding-left: 15px;
    }
    body.responsive a.link_block_xs {
        display: block;
        padding: 8px 0;
        margin-top: 5px;
        border-top: 1px solid #d8d8d8;
        border-bottom: 1px solid #d8d8d8;
        text-decoration: none;
        padding-left: 15px;
        padding-right: 25px;
        position: relative;
    }
    body.responsive a.link_block_xs:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
    body.responsive img {
        max-width: 100%;
        height: auto !important;
    }
    body.responsive iframe {
        max-width: 100%;
    }
    body.responsive table {
        max-width: 100%;
    }
    body.responsive img.alignright {
        max-width: 45%;
        height: auto !important;
    }
    body.responsive img.alignleft {
        max-width: 45%;
        height: auto !important;
    }
    body.responsive img.alignright {
        max-width: 100%;
        float: none;
        margin: 4px auto;
    }
    body.responsive img.alignleft {
        max-width: 100%;
        float: none;
        margin: 4px auto;
    }
    body.responsive img.fullwidth {
        max-width: 100%;
        margin: 5px 0;
        height: auto !important;
    }
    body.responsive img.aligncenter {
        max-width: 100%;
        margin: 5px auto;
        height: auto !important;
    }
    body.responsive .responsive_hidden {
        display: none;
    }
    body.responsive .responsive_shown {
        display: block;
    }
    body.responsive .module_article_image {
        float: none;
    }
    body.responsive .module_listing_container > div {
        min-height: 0px !important;
    }
    body.responsive #header img {
        max-width: 100%;
    }
    body.responsive #header .logo {
        float: none;
        margin-top: 46px;
        margin-bottom: 0px;
        text-align: center;
        width: auto;
    }
    body.responsive #header .logo img {
        margin: 0 auto;
    }
    body.responsive #header .header_nav .search_trigger {
        width: 20px;
        height: 20px;
    }
    body.responsive #header .header_strapline {
        float: none !important;
        font-size: 1.3em;
        margin: 25px auto 0 !important;
        max-width: 80%;
        text-align: center;
    }
    body.responsive #header .header_strapline p {
        font-size: 1.5em;
    }
    body.responsive .calendar_week_labels {
        display: none;
    }
    body.responsive .calendar_day {
        width: auto;
        float: none;
    }
    body.responsive .calendar_day_inner {
        height: auto !important;
        min-height: 40px;
    }
    body.responsive .other_month {
        display: none;
    }
    body.responsive .calendar_day_number {
        right: auto;
        left: 10px;
    }
    body.responsive a.calendar_event {
        margin-right: 0;
        margin-left: 27px;
    }
    body.responsive table.responsive,
    body.responsive table.responsive thead,
    body.responsive table.responsive tfoot,
    body.responsive table.responsive tbody,
    body.responsive table.responsive th,
    body.responsive table.responsive td,
    body.responsive table.responsive tr {
        display: block;
        width: auto !important;
    }
    body.responsive table.responsive_transpose,
    body.responsive table.responsive_transpose thead,
    body.responsive table.responsive_transpose tfoot,
    body.responsive table.responsive_transpose tbody,
    body.responsive table.responsive_transpose th,
    body.responsive table.responsive_transpose td,
    body.responsive table.responsive_transpose tr {
        display: block;
        width: auto !important;
    }
    body.responsive table.responsive_transpose thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }
    body.responsive table.responsive_transpose tbody td {
        position: relative;
        white-space: normal;
        text-align: left;
        padding-left: 145px !important;
    }
    body.responsive table.responsive_transpose tfoot td {
        text-align: left;
    }
    body.responsive table.responsive_transpose td.row_header {
        padding-left: 5px;
        background: #777;
        color: #fff;
    }
    body.responsive table.responsive_transpose td.row_header:before {
        display: none;
    }
    body.responsive table.responsive_transpose tbody td:before {
        position: absolute;
        top: 0px;
        left: 0px;
        padding-top: 8px;
        padding-left: 5px;
        width: 140px;
        height: 100%;
        padding-right: 10px;
        white-space: nowrap;
        text-align: left;
        font-weight: bold;
        box-sizing: border-box;
        content: attr(data-title);
    }
    body.responsive .home_intro h1 {
        font-size: 2em;
    }
    body.responsive .home_intro > div:first-child img {
        margin-bottom: 14px;
        width: auto;
        height: auto;
        max-width: 100%;
    }
    body.responsive .home_intro p:last-child > a:first-child:last-child {
        left: 15px;
        right: auto;
    }
    body.responsive .module_featured_image {
        margin-bottom: 15px;
    }
    body.responsive p.module_inline_options {
        text-align: center;
    }
    body.responsive .lower_banner img {
        max-width: none !important;
        height: 100px !important;
    }
    body.responsive .lower_banner p {
        padding: 15px;
        font-size: 2em;
    }
    body.responsive .info_box {
        min-height: 0px !important;
    }
    body.responsive .info_box .box_image {
        height: auto;
    }
    body.responsive .search_layer .search_box {
        margin-left: -150px;
        width: 300px;
    }
    body.responsive .search_layer .search_box h2 {
        font-size: 2.25em;
    }
    body.responsive .prompt {
        float: none;
        width: 100%;
    }
    body.responsive .prompt .prompt_icon {
        height: auto;
    }
    body.responsive #footer {
        text-align: center;
    }
    body.responsive #footer .logos {
        display: inline-block;
        float: none;
        margin-bottom: 10px;
    }
    body.responsive #footer .logos + p {
        clear: both;
    }
    body.responsive .module_article_social {
        position: static;
    }
    body.responsive .cycle_banner_container .banner_item_title {
        font-size: 2em;
    }
    body.responsive .cycle_banner_container {
        height: 380px !important;
    }
    body.responsive .banner_item_description_column {
        background: none;
    }
    body.responsive .banner_item_description_container {
        background-color: rgba(0, 0, 0, 0.5);
        margin-top: 40px;
        padding: 15px;
    }
}

@media screen and (max-width: 400px) {
    body.responsive #header .header_strapline {
        float: none !important;
        max-width: 100%;
        text-align: center;
    }
    body.responsive #header .header_nav ul li a {
        font-size: 0.85em;
        padding: 6px;
    }
    body.responsive #header .logo img {
        width: 65%;
    }
    body.responsive .explore_more {
        margin-top: 15px;
    }
    body.responsive .banner_item_description_container {
        margin-top: 20px;
    }
    body.responsive .cycle_banner_container .banner_item_title {
        font-size: 1.8em;
    }
}
