/*@import '../../../../Core/_resources/css/custom/colours';*/
/* CSS for banner module using jQuery Cycle */
.cycle_banner_container {
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-align: center;
}

.cycle_banner_container .banner_content {
    width: 100%;
    height: 100%;
}

.cycle_banner_container .banner_content img {
    width: 100%;
    height: 100%;
}

.cycle_banner_container .banner_item {
    background-size: cover !important;
    width: 100% !important;
    height: 100% !important;
}

.banner_item_description_column {
    position: absolute;
    z-index: 99;
    top: 0px;
    bottom: 0px;
    left: 0;
    background: #000000;
}

.cycle_banner_container .banner_item_description_container {
    text-align: left;
    color: #fff;
    font-weight: 300;
    font-family: AvantGarde, HelveticaNeue, Arial, Helvetica, Sans-Serif;
    padding: 30px 0px 0px 15px;
}

.cycle_banner_container .banner_item_link {
    display: block;
    position: absolute;
    background: url("../../../../_resources/images/blank.gif") repeat;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 98;
}

.cycle_banner_container .banner_item_title {
    font-size: 3em;
    line-height: 1.1em;
    margin-bottom: 14px;
}

.cycle_banner_container .banner_item_description {
    padding-top: 0;
    font-size: 1em;
    line-height: 1.4em;
}

.cycle_banner_container .banner_item_description .date {
    display: block;
    margin-bottom: 14px;
    font-size: 1.5em;
    line-height: 1.25em;
}

.cycle_banner_container .banner_item_description a {
    color: #D31E7D;
}

.cycle_banner_container .banner_item_description a.explore_more {
    color: #ffffff !important;
}

.cycle_banner_container .banner_item_description p {
    margin: 5px 0 0 0;
}

.cycle_banner_container .banner_item_description a.explore_more {
    position: absolute;
    display: block;
    padding: 6px 12px;
    bottom: 15px;
    right: 15px;
    text-decoration: none;
    font-size: 1.1em;
    line-height: 1em;
    background-color: #D31E7D;
}

.cycle_banner_container .banner_item_description a.explore_more:hover {
    background-color: #F33E9D;
}

.cycle_banner_container p.copyright {
    position: absolute;
    top: 15px;
    right: 15px;
    margin: 0px;
    padding: 4px 7px;
    color: #FFFFFF;
    font-size: 0.8em;
    line-height: 1.4em;
    background-color: #000000;
}

.cycle_banner_container .banner_pager {
    position: absolute;
    z-index: 100;
    bottom: 20px;
    right: 20px;
}

.cycle_banner_container .banner_pager a {
    display: block;
    float: left;
    margin-left: 6px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    color: #000000;
    text-decoration: none;
    text-align: center;
    font-size: 1.2em;
    line-height: 20px;
    background-color: #FFFFFF;
}

.cycle_banner_container .banner_pager a.cycle-pager-active {
    color: #FFFFFF;
    background-color: #1a58bb;
}

.cycle_banner_container .banner_pager_next,
.cycle_banner_container .banner_pager_previous {
    position: absolute;
    bottom: 0px;
    width: 47px;
    height: 41px;
    font-size: 60px;
    z-index: 100;
    color: #666;
    color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
    background-color: #000000;
    background-repeat: no-repeat;
    background-position: center;
}

.cycle_banner_container .banner_pager_next:hover,
.cycle_banner_container .banner_pager_previous:hover {
    color: #000;
}

.cycle_banner_container .banner_pager_next {
    right: 0px;
    background-image: url("../images/banner_next.png");
}

.cycle_banner_container .banner_pager_previous {
    left: 0px;
    background-image: url("../images/banner_previous.png");
}

a.edit_banner {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 100;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
