.modEventsFilterBar {
    padding: 0 15px;
    background: #f1f1f1;
    border-radius: 4px;
}

.modEventsFilterBar button {
    white-space: nowrap;
}

.modEventsFilterBar .dropdown-menu {
    max-height: 205px;
    overflow: auto;
}

.events_view_filters {
    background: #f1f1f1;
}

.events_range {
    font-size: 0.7em;
    color: #666;
}

.events_previous_date .glyphicon,
.events_next_date .glyphicon {
    font-size: 3em;
    color: #666;
    margin-top: -5px;
    vertical-align: middle;
    line-height: 42px;
}

.events_previous_date span,
.events_next_date span {
    vertical-align: middle;
    line-height: 38px;
}

.events_previous_date .glyphicon {
    float: left;
}

.events_next_date .glyphicon {
    float: right;
}

.events_previous_date a,
.events_next_date a {
    color: #666;
    display: inline-block;
    height: 42px;
    text-decoration: none;
    vertical-align: middle;
}

.events_view_title {
    font-size: 1.3em;
}

.events_view_title h2 {
    margin-top: 0;
}

.events_heading_row {
    padding: 5px 15px;
    background: #444;
    color: #fff;
    margin-top: 15px;
}

.month_row {
    background: #eee;
    font-weight: bold;
    color: #444;
    font-size: 1.2em;
    padding: 5px 15px;
}

.date_row {
    padding: 10px 15px;
}

.date_row:hover {
    background: #FFE8C5;
}

.date_passed {
    opacity: 0.7;
}

a.calendar_event {
    font-size: 0.9em;
    line-height: 1.4em;
    background: #1557b2;
    color: #fff;
    padding: 3px 6px;
    display: block;
    margin-right: 20px;
    margin-bottom: 2px;
    border-radius: 4px;
    text-decoration: none;
}

a.calendar_event:hover {
    background: #0a4493;
}

.calendar_day_number a {
    color: #666;
}

.no_margin {
    margin-top: 0px !important;
}
